/**
 * @file TherapyPlanEditorPhase.tsx
 * Component to edit a single phase of a therapy plan
 * @module TherapyPlanEditor/TherapyPlanEditorPhase
 */
import { Trans, useTranslation } from 'react-i18next';
import { AutocompleteValue } from '@mui/material/Autocomplete';
import { doc, DocumentReference } from 'firebase/firestore';
import { TaskPoolTask, TherapyPlanPhase } from '../../firebase/firebaseModels';
import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { useFirestore } from 'reactfire';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import { styled } from '@mui/system';
import TranslatableTextField from '../TranslatableTextField';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import { TaskPoolSelector } from '../TherapyCourseEditor/TaskPoolSelector';
import { usePhaseTasksValue } from '../TherapyCourseEditor/usePhaseTasksValue';

type TherapyPlanEditorPhaseProps = {
  disabled: boolean;
  phases: TherapyPlanPhase[];
  currentPhase: number;
  language: string;
  defaultLanguage: string;
  setPhases: Dispatch<SetStateAction<TherapyPlanPhase[]>>;
  taskPoolOptions: TaskPoolOption[];
};

export default function TherapyPlanEditorPhase(
  props: TherapyPlanEditorPhaseProps
) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();
  const {
    disabled,
    phases,
    currentPhase,
    language,
    setPhases,
    taskPoolOptions,
    defaultLanguage,
  } = props;

  const phaseTasks = useMemo(
    () => phases[currentPhase]?.phaseTasks || [],
    [phases, currentPhase]
  );

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const phaseTasksValue: TaskPoolOption[] | null = usePhaseTasksValue(
    phaseTasks,
    taskPoolData,
    categoryData
  );
  // const phaseTasksValue = useMemo(
  //   () =>
  //     phaseTasks
  //       .map((task) => {
  //         const taskPoolTask = taskPoolData?.find((tpt) => tpt.id === task.id);
  //         const category = categoryData?.find(
  //           (cat) => cat.id === taskPoolTask?.category?.id
  //         );

  //         return {
  //           id: taskPoolTask?.id as string,
  //           translatedTitle: taskPoolTask?.title
  //             ? getTranslatableField(taskPoolTask.title)
  //             : (taskPoolTask?.id as string),
  //           categoryTitle: !!category
  //             ? getTranslatableField(category.title)
  //             : t('Uncategorized'),
  //           categoryColor: category?.color || '#000000',
  //         };
  //       })
  //       .sort((a, b) => {
  //         const sortTitleA = a.categoryTitle + '/' + a.translatedTitle;
  //         const sortTitleB = b.categoryTitle + '/' + b.translatedTitle;
  //         return sortTitleA.localeCompare(sortTitleB);
  //       }),
  //   [phaseTasks, taskPoolData, categoryData, t]
  // );

  const onChangeTitle = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    (event) => {
      const newPhase = phases[currentPhase];
      newPhase.title = {
        ...newPhase.title,
        [language]: event.target.value,
      };
      setPhases([
        ...phases.slice(0, currentPhase),
        newPhase,
        ...phases.slice(currentPhase + 1),
      ]);
    },
    [phases, currentPhase, language, setPhases]
  );

  const onChangeDefaultTasks = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<
        {
          id: string;
          translatedTitle: string;
          categoryTitle: string;
          categoryColor: string;
        },
        true,
        false,
        false
      >
    ) => {
      console.log('newValue', newValue);
      setPhases((phases) => {
        const newPhase = phases[currentPhase];
        newPhase.phaseTasks = newValue.map(
          (v) =>
            doc(
              firestore,
              'TaskPool',
              v.id as string
            ) as DocumentReference<TaskPoolTask>
        );
        return [
          ...phases.slice(0, currentPhase),
          newPhase,
          ...phases.slice(currentPhase + 1),
        ];
      });
    },
    [currentPhase, firestore, setPhases]
  );
  const titleExistsInPrimaryLanguage =
    !!phases[currentPhase].title[defaultLanguage];

  if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
    return <CircularProgress />;
  }
  return (
    <>
      {collectionError && (
        <Alert severity="error">
          <AlertTitle>{t('Error')}</AlertTitle>
          <Trans>{collectionError.message}</Trans>
        </Alert>
      )}
      <TranslatableTextField
        key={`phase-title-${language}-${currentPhase}`}
        disabled={disabled}
        label={t('Audio Section Title')}
        helperText={
          !titleExistsInPrimaryLanguage
            ? t('Title is required in primary language') +
              ' ' +
              `(${defaultLanguage})`
            : undefined
        }
        error={!titleExistsInPrimaryLanguage}
        language={language}
        type="title"
        onChange={onChangeTitle}
        value={phases[currentPhase].title}
        placeholder={t('Enter Title')}
      />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        {t('Default Tasks for this Phase')}
      </Typography>
      <TaskPoolSelector
        taskPoolOptions={taskPoolOptions}
        phaseTasksValue={phaseTasksValue!}
        onChangePhaseTasks={onChangeDefaultTasks}
      />
    </>
  );
}

export const CategoryGroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
}));

export const CategoryGroupItems = styled('ul')({
  padding: 0,
});
