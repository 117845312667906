import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import uuid from 'react-native-uuid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import {
  doc,
  collection,
  updateDoc,
  addDoc,
  Timestamp,
  DocumentReference,
} from 'firebase/firestore';
import {
  TaskPoolTask,
  TaskPoolTaskOptions,
  TaskPoolTaskType,
  TranslatableField,
  TaskPoolTaskSectionType,
  TaskPoolTaskSection,
  TaskPoolTaskCategory,
} from '../firebase/firebaseModels';
import {
  AlertTitle,
  CardHeader,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useTaskPoolTaskDoc from '../firebase/useTaskPoolTaskDoc';
import LanguageSelector from '../components/LanguageSelector';
import { useFirestore } from 'reactfire';
import { MuiFileInput } from 'mui-file-input';
import { DEFAULT_IMAGE_URL } from '../consts';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TaskPoolTaskAudioSectionEditor from '../components/TaskPoolTaskAudioSectionEditor';
import TaskPoolTaskVideoSectionEditor from '../components/TaskPoolTaskVideoSectionEditor';
import TaskPoolTaskImageSectionEditor from '../components/TaskPoolTaskImageSectionEditor';
import TaskPoolTaskPDFSectionEditor from '../components/TaskPoolTaskPDFSectionEditor';
import TaskPoolTaskURLSectionEditor from '../components/TaskPoolTaskURLSectionEditor';
import TaskPoolTaskRadioSectionEditor from '../components/TaskPoolTaskRadioSectionEditor';
import TaskPoolTaskRangeSectionEditor from '../components/TaskPoolTaskRangeSectionEditor';
import TaskPoolTaskCheckboxSectionEditor from '../components/TaskPoolTaskCheckboxSectionEditor';
import TaskPoolTaskInfoPopupSectionEditor from '../components/TaskPoolTaskInfoPopupSectionEditor';
import { getCurrentLanguageCode } from '../services/i18n';
import LoadingButton from '@mui/lab/LoadingButton';
import usePortalUser from '../firebase/usePortalUser';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useStrictDroppable } from '../hooks/useStrictDroppable';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import CreatedModifiedTable from '../components/CreatedModifiedTable';
import useUserProvider from '../firebase/useUserProvider';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import { useUpload } from '../hooks/useUpload';
import TaskPoolTaskQuestionSectionEditor from '../components/TaskPoolTaskQuestionSectionEditor';
import TranslatableTextField from '../components/TranslatableTextField';
import TranslatableEditor from '../components/TranslatableEditor';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type TaskTypeOption = {
  value: TaskPoolTaskType;
  label: string;
};

export type SaveFuncParams = {
  success: boolean;
  message: string;
  newSection?: TaskPoolTaskSection;
  error?: Error;
};

export type SectionEditorRef = {
  sectionType: TaskPoolTaskSectionType;
  save: () => Promise<SaveFuncParams>;
};

type SaveError = {
  message: string;
  error?: Error;
};

/**
 * Editing the TaskPoolTask.
 */
export default function TaskPoolTaskEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { taskPoolTaskId, taskpooltype, idToClone } = useParams<{
    taskPoolTaskId: string;
    taskpooltype: string;
    idToClone?: string;
  }>();
  const firestore = useFirestore();

  const { email } = useFirebaseUser();
  const { status: userProviderStatus, providerRef } = useUserProvider();

  const {
    data: portalUserData,
    isSuperAdmin,
    isOrganizationAdmin,
  } = usePortalUser();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const [defaultLanguage, setDefaultLanguage] = useState(
    getCurrentLanguageCode()
  );
  const [title, setTitle] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });

  const [language, setLanguage] = useState(defaultLanguage);

  const [titleDone, setTitleDone] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [description, setDescription] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [amOwner, setAmOwner] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(DEFAULT_IMAGE_URL);
  const [order, setOrder] = useState<number>(0);
  const [options, setOptions] = useState<TaskPoolTaskOptions>({});
  const [allowNotification, setAllowNotification] = useState<boolean>(false);
  const [sections, setSections] = useState<TaskPoolTaskSection[]>([]);
  const [updateSuccessful, setUpdateSuccessful] = useState<boolean>(false);
  const [saveErrors, setSaveErrors] = useState<SaveError[]>([]);
  const [category, setCategory] =
    useState<DocumentReference<TaskPoolTaskCategory> | null>(null);
  const sectionEditorsRef = useRef<SectionEditorRef[]>([]);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogType, setDialogType] = useState<
    'save' | 'promote' | 'demote' | null
  >(null);
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const [addError, setAddError] = useState<Error | null>(null);

  const [type, setType] = useState<TaskPoolTaskType>(
    'generic' as TaskPoolTaskType
  );

  const {
    status: categoryStatus,
    data: categoryData,
    error: categoryError,
  } = useTaskPoolTaskCategoryCollection(
    isPublic ? 'organization_and_default' : null,
    portalUserData?.therapyLanguages || []
  );

  const { uploadFile: uploadImage } = useUpload(image, 'taskpoolimages');

  const handleFileInputChange = useCallback((file: File | null) => {
    setImage(file);
  }, []);

  const handlePublicChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setIsPublic(event.target.checked),
    []
  );

  const { status, error, data } = useTaskPoolTaskDoc(
    taskPoolTaskId as string,
    idToClone
  );
  const [updating, setUpdating] = useState(false);

  const [enabled] = useStrictDroppable(status !== 'success');
  const isGloballyAvailable = isPublic && data?.provider === null;

  const canEdit =
    isSuperAdmin ||
    amOwner ||
    (!amOwner && data?.provider?.id === providerRef?.id && isOrganizationAdmin);

  useEffect(() => {
    if (status === 'success' && data) {
      const defaultLanguage = data.defaultLanguage;
      setTitle(data.title || { [defaultLanguage]: '' });
      setTitleDone(data.titleDone || { [defaultLanguage]: '' });
      setDescription(data.description || { [defaultLanguage]: '' });
      setImageUrl(data.image);
      setOrder(data.order);
      setOptions(data.options || {});
      setAllowNotification(data.allowNotification || false);
      setSections(
        data.sections && (data.sections as any)?.map ? data.sections : []
      );
      setType(data.type);
      setLanguage(defaultLanguage);
      setCategory(data.category || null);
      setAmOwner(data.amOwner || false);
      setDefaultLanguage(defaultLanguage);
      if (taskPoolTaskId === 'new') {
        setIsPublic(taskpooltype !== 'own');
      } else {
        setIsPublic(data.public || false);
      }
    }
  }, [data, taskPoolTaskId, status, taskpooltype]);

  const uploadSectionData = useCallback(
    async (onFinish: (newSections: TaskPoolTaskSection[]) => void) => {
      const newSections = [...sections];
      for (let idx = 0; idx < sections.length; idx++) {
        const sectionEditor = sectionEditorsRef.current[idx];
        if (sectionEditor) {
          const { success, message, newSection, error } =
            await sectionEditor.save();
          if (success) {
            newSections[idx] = newSection as TaskPoolTaskSection;
          }
          if (!success) {
            const newSaveError: SaveError = {
              message,
              error,
            };
            setSaveErrors((errors) => [...errors, newSaveError]);
          }
        }
      }
      setSections(newSections);
      onFinish(newSections);
    },
    [sections]
  );

  let languages = useMemo(() => {
    const langs = Object.keys(title).filter((lang) => !!title[lang]);

    Object.keys(description).forEach((lang) => {
      if (!!description[lang] && !langs.includes(lang)) {
        langs.push(lang);
      }
    });
    return langs;
  }, [description, title]);

  const updateTaskDoc = useCallback(
    (newSections: TaskPoolTaskSection[]) => {
      if (!taskPoolTaskId || !email) {
        return;
      }
      const updateData: Partial<TaskPoolTask> = {
        title,
        titleDone,
        description,
        order,
        options: options || {},
        allowNotification,
        sections: newSections || [],
        image: imageUrl,
        type,
        languages,
        category,
        defaultLanguage,
        public: isPublic,
        modified: Timestamp.now(),
        modifiedBy: email,
      };
      updateDoc(doc(firestore, 'TaskPool', taskPoolTaskId), updateData)
        .then(() => {
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdateError(error);
          setUpdating(false);
        });
    },
    [
      taskPoolTaskId,
      title,
      titleDone,
      description,
      order,
      options,
      allowNotification,
      imageUrl,
      type,
      languages,
      category,
      defaultLanguage,
      isPublic,
      email,
      firestore,
    ]
  );

  const saveTaskDoc = useCallback(
    (newSections: TaskPoolTaskSection[]) => {
      if (!email || !providerRef) {
        return;
      }
      const newData: TaskPoolTask = {
        title,
        titleDone,
        description,
        image: imageUrl,
        defaultLanguage,
        languages,
        order,
        options: options || {},
        allowNotification,
        sections: newSections || [],
        type,
        createdBy: email,
        created: Timestamp.now(),
        provider: providerRef,
        category,
        public: isPublic,
      };

      addDoc(collection(firestore, 'TaskPool'), newData)
        .then((docRef) => {
          uploadImage((url: string | null) => {
            if (url) {
              updateDoc(doc(firestore, 'TaskPool', docRef.id), {
                image: url,
              }).then(() => {
                navigate(`/admin/taskpool/own/${docRef.id}`);
                setUpdateSuccessful(true);
                setUpdateError(null);
                setUpdating(false);
              });
            } else {
              navigate(`/admin/taskpool/own/${docRef.id}`);
              setUpdateSuccessful(true);
              setUpdateError(null);
              setUpdating(false);
            }
          });
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
          setAddError(error);
          setUpdating(false);
        });
    },
    [
      allowNotification,
      category,
      defaultLanguage,
      description,
      email,
      firestore,
      imageUrl,
      isPublic,
      languages,
      navigate,
      options,
      order,
      providerRef,
      title,
      titleDone,
      type,
      uploadImage,
    ]
  );

  const updateTask = useCallback(() => {
    if (!title || !description) {
      return;
    }
    setUpdating(true);

    if (taskPoolTaskId && taskPoolTaskId !== 'new') {
      setUpdating(true);
      setUpdateSuccessful(false);
      setSaveErrors([]);
      uploadImage((url) => {
        if (url) {
          setImageUrl(url);
        }
      });
      uploadSectionData(updateTaskDoc);
    } else {
      uploadSectionData(saveTaskDoc);
    }
  }, [
    title,
    description,
    taskPoolTaskId,
    uploadImage,
    uploadSectionData,
    updateTaskDoc,
    saveTaskDoc,
  ]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!title || !description) {
        return;
      }
      if (!amOwner) {
        setDialogDescription(
          t(
            'You are not the owner of this task. Are you sure you want to save changes?'
          )
        );
        setDialogOpen(true);
        setDialogType('save');
        return;
      }
      updateTask();
    },
    [title, description, amOwner, updateTask, t]
  );

  const confirmPromoteToGlobal = useCallback(() => {
    if (!taskPoolTaskId) {
      return;
    }
    const taskRef = doc(firestore, 'TaskPool', taskPoolTaskId);
    updateDoc(taskRef, {
      public: true,
      provider: null,
    });
  }, [firestore, taskPoolTaskId]);

  const confirmDemoteToPrivate = useCallback(() => {
    if (!taskPoolTaskId || !providerRef) {
      return;
    }
    const taskRef = doc(firestore, 'TaskPool', taskPoolTaskId);
    updateDoc(taskRef, {
      public: false,
      provider: providerRef,
    });
  }, [firestore, providerRef, taskPoolTaskId]);

  const promoteToGlobal = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(t('Are you sure you want to make this task global?'));
    setDialogOpen(true);
    setDialogType('promote');
  }, [isSuperAdmin, t]);

  const demoteToPrivate = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(t('Are you sure you want to make this task private?'));
    setDialogOpen(true);
    setDialogType('demote');
  }, [isSuperAdmin, t]);

  const handleConfirm = useCallback(() => {
    setDialogOpen(false);
    switch (dialogType) {
      case 'save':
        updateTask();
        break;
      case 'promote':
        confirmPromoteToGlobal();
        break;
      case 'demote':
        confirmDemoteToPrivate();
        break;
    }
  }, [confirmDemoteToPrivate, confirmPromoteToGlobal, dialogType, updateTask]);

  const taskTypeOptions: TaskTypeOption[] = useMemo(() => {
    return taskPoolTaskId === 'new'
      ? [
          { value: 'generic' as TaskPoolTaskType, label: t('Home Task') },
          {
            value: 'questionnaire' as TaskPoolTaskType,
            label: t('Questionnaire'),
          },
        ]
      : [
          { value: 'generic' as TaskPoolTaskType, label: t('Home Task') },
          {
            value: 'questionnaire' as TaskPoolTaskType,
            label: t('Questionnaire'),
          },
          { value: 'homework' as TaskPoolTaskType, label: t('Homework') },
          { value: 'morning' as TaskPoolTaskType, label: t('Morning') },
          { value: 'evening' as TaskPoolTaskType, label: t('Evening') },
          {
            value: 'sleepRestriction' as TaskPoolTaskType,
            label: t('Sleep Restriction'),
          },
        ];
  }, [taskPoolTaskId, t]);

  const taskSectionTypeOptions = useMemo(() => {
    switch (type) {
      case 'questionnaire':
      case 'generic':
        return [
          { value: 'pdf' as TaskPoolTaskSectionType, label: t('PDF') },
          { value: 'url' as TaskPoolTaskSectionType, label: t('Web Link') },
          {
            value: 'infoPopup' as TaskPoolTaskSectionType,
            label: t('Info Popup'),
          },
          {
            value: 'radio' as TaskPoolTaskSectionType,
            label: t('Multiple Question'),
          },
          {
            value: 'range' as TaskPoolTaskSectionType,
            label: t('Range Question'),
          },
          {
            value: 'checkbox' as TaskPoolTaskSectionType,
            label: t('Yes/No Question'),
          },
          {
            value: 'question' as TaskPoolTaskSectionType,
            label: t('Free Text Question'),
          },
          { value: 'image' as TaskPoolTaskSectionType, label: t('Image') },
          { value: 'audio' as TaskPoolTaskSectionType, label: t('Audio') },
          { value: 'video' as TaskPoolTaskSectionType, label: t('Video') },
        ];
      default:
        return [];
    }
  }, [t, type]);

  // const getOptionLabel = useCallback(
  //   (option: { id: string; title: TranslatableField }) =>
  //     option.title[language] ||
  //     `${option.title[Object.keys(option.title)[0]]} [${
  //       Object.keys(option.title)[0]
  //     }]`,
  //   [language]
  // );

  const taskCategoryOptions = useMemo(() => {
    return (categoryData
      ?.filter((category) => category.id)
      .map((category) => ({
        id: category.id,
        title:
          category.title[language] ||
          `${category.title[Object.keys(category.title)[0]]} [${
            Object.keys(category.title)[0]
          }]`,
        color: category.color,
      })) || []) as { id: string; title: string; color: string }[];
  }, [categoryData, language]);

  const categoryValue = useMemo(
    () =>
      taskCategoryOptions?.find((option) => option.id === category?.id) || null,
    [category, taskCategoryOptions]
  );

  const handleCategoryChange = useCallback(
    (_: any, newValue: any) => {
      const categoryRef = newValue?.id
        ? doc(firestore, 'TaskPoolTaskCategories', newValue?.id)
        : null;
      setCategory(
        categoryRef as DocumentReference<TaskPoolTaskCategory> | null
      );
    },
    [firestore]
  );

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      const items = [...sections];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setSections(items);
    },
    [sections]
  );

  const grid = 1;

  const getItemStyle = useCallback(
    (
      isDragging: boolean,
      draggableStyle?: DraggingStyle | NotDraggingStyle
    ): any => {
      return {
        // some basic styles to make the items look a bit nicer.
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'transparent',

        // styles we need to apply on draggables
        ...draggableStyle,
      };
    },
    []
  );

  const handleFrequencyChange = useCallback(
    (event: SelectChangeEvent<TaskPoolTaskOptions['frequency']>) => {
      setOptions((options) => ({
        ...options,
        frequency: event.target.value as TaskPoolTaskOptions['frequency'],
      }));
    },
    []
  );

  const handleOrderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setOrder(Number(event.target.value)),
    []
  );

  const handleAllowNotificationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setAllowNotification(event.target.checked),
    []
  );

  const handleDeleteSection = useCallback((section: TaskPoolTaskSection) => {
    setSections((sections) => {
      const newSections = sections?.filter((s) => s.id !== section.id) || [];
      return newSections;
    });
  }, []);

  const getListStyle = useCallback((isDraggingOver: boolean): any => {
    return {
      background: isDraggingOver ? 'lightblue' : 'transparent',
      padding: grid,
    };
  }, []);

  const sectionsList = useMemo(
    () => (
      <DragDropContext onDragEnd={onDragEnd}>
        {enabled && (
          <Droppable droppableId="sections" key="sections" direction="vertical">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {sections?.map((section, idx) => {
                  return (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {section.type === 'audio' && (
                            <TaskPoolTaskAudioSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'video' && (
                            <TaskPoolTaskVideoSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'image' && (
                            <TaskPoolTaskImageSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'pdf' && (
                            <TaskPoolTaskPDFSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'url' && (
                            <TaskPoolTaskURLSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'radio' && (
                            <TaskPoolTaskRadioSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'range' && (
                            <TaskPoolTaskRangeSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'checkbox' && (
                            <TaskPoolTaskCheckboxSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'infoPopup' && (
                            <TaskPoolTaskInfoPopupSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'question' && (
                            <TaskPoolTaskQuestionSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    ),
    [
      onDragEnd,
      enabled,
      getListStyle,
      sections,
      getItemStyle,
      canEdit,
      language,
      defaultLanguage,
      handleDeleteSection,
    ]
  );
  const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

  if (!taskPoolTaskId) {
    return <Paper>{t('Task ID not provided')}</Paper>;
  }

  if (status === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {categoryError && <Alert severity="error">{categoryError.message}</Alert>}
      {userProviderStatus === 'loading' && <CircularProgress />}
      <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
        <Card sx={{ mt: 2 }} variant="outlined" key="language">
          <CardHeader
            title={t('Basics')}
            subheader={t('Select the language to edit')}
            action={
              <LanguageSelector
                disabled={!canEdit}
                language={language}
                defaultLanguage={defaultLanguage}
                setLanguage={setLanguage}
                setDefaultLanguage={setDefaultLanguage}
              />
            }
          />
          <CardContent>
            <TranslatableTextField
              key={`task-title-${language}`}
              disabled={!canEdit}
              label={t('Task Name')}
              helperText={
                !titleExistsInPrimaryLanguage
                  ? t('Name is required in primary language')
                  : undefined
              }
              error={!titleExistsInPrimaryLanguage}
              language={language}
              type="title"
              setValue={setTitle}
              value={title}
              placeholder={t('Enter Name')}
            />
            <TranslatableTextField
              key={`task-titleDone-${language}`}
              disabled={!canEdit}
              label={t('Task Name When User Has Marked is Done')}
              placeholder={t(
                'Enter the name the app user sees when task is done (optional)'
              )}
              language={language}
              type="title"
              setValue={setTitleDone}
              value={titleDone}
              sx={{ mt: 2 }}
            />

            <TranslatableEditor
              key={`task-description-${language}`}
              disabled={!canEdit}
              placeholder={t('Description')}
              value={description}
              setValue={setDescription}
              language={language}
              type="description"
            />
            <Autocomplete
              options={taskTypeOptions}
              blurOnSelect
              disabled={taskPoolTaskId !== 'new' || !canEdit}
              getOptionLabel={(option) => option.label}
              value={taskTypeOptions.find((option) => option.value === type)}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, newValue) =>
                setType((newValue?.value || 'task') as TaskPoolTaskType)
              }
              renderInput={(params) => (
                <TextField {...params} label={t('Type')} placeholder="" />
              )}
              sx={{ mt: 2 }}
            />
            <Autocomplete
              disabled={!canEdit}
              loading={categoryStatus === 'loading'}
              blurOnSelect
              options={taskCategoryOptions}
              value={categoryValue}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleCategoryChange}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box
                    component="div"
                    sx={{
                      mr: 2,
                      backgroundColor: option.color,
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      display: 'inline-flex',
                    }}
                  />
                  {option.title}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Task Category')}
                  placeholder={t('Select task category...')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: categoryValue ? (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            backgroundColor: categoryValue.color,
                          }}
                        />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
              sx={{ mt: 2 }}
            />
          </CardContent>
        </Card>

        {taskSectionTypeOptions.length > 0 && (
          <Card sx={{ mt: 2 }} variant="outlined">
            <CardHeader
              title={t('Sections')}
              subheader={t('Select the language to edit')}
              action={
                <LanguageSelector
                  language={language}
                  defaultLanguage={defaultLanguage}
                  setLanguage={setLanguage}
                  setDefaultLanguage={setDefaultLanguage}
                />
              }
            />
            <CardContent>
              {sectionsList}
              {/** add section; display dropdown for type and button for adding */}
              <Grid container spacing={2}>
                <Grid item>
                  <Autocomplete
                    disabled={!canEdit}
                    blurOnSelect
                    value={null}
                    options={taskSectionTypeOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        const newId = uuid.v4() as string;
                        const newSection: TaskPoolTaskSection = {
                          id: newId,
                          title: { [defaultLanguage]: '' },
                          description: { [defaultLanguage]: '' },
                          type: newValue.value,
                        };
                        setSections((sections) => {
                          return [...(sections || []), newSection];
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Add Section')}
                        placeholder=""
                      />
                    )}
                    sx={{ mt: 2, minWidth: 200 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        <Card sx={{ mt: 2 }} variant="outlined">
          <CardHeader title={t('Image')} />
          <CardContent>
            <Grid container alignItems="center">
              <Grid item>
                {imageUrl && !image && (
                  <Avatar
                    variant="square"
                    alt="Task Image"
                    src={imageUrl}
                    sx={{ width: 128, height: 128, borderRadius: 0, mt: 1 }}
                  />
                )}
                {image && (
                  <Avatar
                    variant="square"
                    alt="Task Image"
                    src={URL.createObjectURL(image)}
                    sx={{ width: 128, height: 128, borderRadius: 0, mt: 1 }}
                  />
                )}
              </Grid>
            </Grid>
            <MuiFileInput
              disabled={!canEdit}
              label={t('Upload Image')}
              inputProps={{ accept: 'image/*' }}
              onChange={handleFileInputChange}
              sx={{ mt: 2 }}
              variant="outlined"
              clearIconButtonProps={{
                title: t('Clear'),
                children: <CloseIcon fontSize="small" />,
              }}
              value={image}
              fullWidth
            />
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }} variant="outlined">
          <CardHeader title={t('App Options')} />
          <CardContent>
            <FormControl fullWidth>
              <InputLabel id="frequency">
                <Trans>Task Frequency</Trans>
              </InputLabel>
              <Select
                disabled={!canEdit}
                labelId="frequency"
                id="frequency"
                value={options.frequency || 'daily'}
                label={t('Task Frequency')}
                onChange={handleFrequencyChange}
              >
                <MenuItem value="daily" key="daily">
                  <Trans>Daily</Trans>
                </MenuItem>
                <MenuItem value="weekly" key="weekly">
                  <Trans>Weekly</Trans>
                </MenuItem>
                <MenuItem value="once" key="once">
                  <Trans>Just Once</Trans>
                </MenuItem>
                <MenuItem value="once_per_phase" key="once_per_phase">
                  <Trans>Once per therapy phase</Trans>
                </MenuItem>
              </Select>
            </FormControl>
            <FormGroup sx={{ mt: 2 }} row>
              <FormControlLabel
                disabled={!canEdit}
                control={
                  <Checkbox
                    checked={allowNotification}
                    onChange={handleAllowNotificationChange}
                  />
                }
                label={t('Allow User to Set A Reminder Notification')}
              />
            </FormGroup>

            <TextField
              label={t('Order')}
              disabled={!canEdit}
              value={order}
              onChange={handleOrderChange}
              fullWidth
              required
              sx={{ mt: 2 }}
            />
          </CardContent>
        </Card>
        {!isGloballyAvailable && (isOrganizationAdmin || isSuperAdmin) && (
          <Card sx={{ mt: 2 }} variant="outlined">
            <CardHeader title={t('Administration')} subheader={t('Options')} />
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  disabled={!isOrganizationAdmin && !isSuperAdmin}
                  control={
                    <Checkbox
                      checked={isPublic}
                      onChange={handlePublicChange}
                    />
                  }
                  label={t('Show this task to all users of organization')}
                />
              </FormGroup>
            </CardContent>
          </Card>
        )}

        {updateSuccessful && (
          <Alert
            severity="success"
            sx={{ mt: 2 }}
            onClose={() => setUpdateSuccessful(false)}
          >
            <AlertTitle>{t('Task updated successfully')}</AlertTitle>
          </Alert>
        )}
        {saveErrors.map(
          (error, idx) =>
            error.error && (
              <Alert key={`alert-${idx}`} severity="error" sx={{ mt: 2 }}>
                <AlertTitle>{t(error.message)}</AlertTitle>
                {error.error.message}
              </Alert>
            )
        )}
        {canEdit && (
          <LoadingButton
            loading={updating}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={
              !email || updating || (data?.type !== 'generic' && !isSuperAdmin)
            }
          >
            {t('Save')}
          </LoadingButton>
        )}
        {isSuperAdmin && isGloballyAvailable && (
          <Button
            color="error"
            variant="contained"
            sx={{ mt: 2, ml: 2 }}
            onClick={demoteToPrivate}
          >
            {t('Make Private')}
          </Button>
        )}
        {isSuperAdmin && !isGloballyAvailable && (
          <Button
            color="error"
            variant="contained"
            sx={{ mt: 2, ml: 2 }}
            onClick={promoteToGlobal}
          >
            {t('Make Default')}
          </Button>
        )}

        <CreatedModifiedTable isNew={taskPoolTaskId === 'new'} data={data} />
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            <Trans>OK</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!updateError}
        autoHideDuration={6000}
        onClose={() => setUpdateError(null)}
      >
        <Alert onClose={() => setUpdateError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Saving Task</Trans>
          </AlertTitle>
          <Trans>{updateError?.message}</Trans>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!addError}
        autoHideDuration={6000}
        onClose={() => setAddError(null)}
      >
        <Alert onClose={() => setAddError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Adding Task</Trans>
          </AlertTitle>
          <Trans>{addError?.message}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
