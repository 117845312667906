import { Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, '0'))
    .join('');
  return hash;
};
export function useCalculateStatisticsDataHash(
  therapyCoursePatientId: string | null,
  startDate: Timestamp | null,
  endDate: Timestamp | null
) {
  const [hash, setHash] = useState<string | null>(null);

  useEffect(() => {
    if (!therapyCoursePatientId) {
      return;
    }
    const startDateStr = startDate?.toDate().toISOString() || '';
    const endDateStr = endDate?.toDate().toISOString() || '';
    const message = `${therapyCoursePatientId}-${startDateStr}-${endDateStr}`;
    getSHA256Hash(message).then(setHash);
  }, [endDate, startDate, therapyCoursePatientId]);

  return hash;
}
