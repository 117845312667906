import { AutocompleteValue, Autocomplete, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import {
  CategoryGroupHeader,
  CategoryGroupItems,
} from '../TherapyPlanEditor/TherapyPlanEditorPhase';

export type TaskPoolSelectorProps = {
  taskPoolOptions: TaskPoolOption[];
  phaseTasksValue: TaskPoolOption[] | null;
  onChangePhaseTasks: (
    _: any,
    newValue: AutocompleteValue<TaskPoolOption, true, false, false>
  ) => void;
};
export function TaskPoolSelector(props: TaskPoolSelectorProps) {
  const { t } = useTranslation();

  const { taskPoolOptions, phaseTasksValue, onChangePhaseTasks } = props;
  if (!taskPoolOptions || phaseTasksValue === null) {
    return null;
  }
  return (
    <Autocomplete
      multiple
      groupBy={(option) => option.categoryTitle}
      id="task-pool-selector"
      options={taskPoolOptions}
      getOptionLabel={(option) => option.translatedTitle}
      value={phaseTasksValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={onChangePhaseTasks}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t('Tasks')}
          placeholder={t('Add...')}
        />
      )}
      renderOption={(props, option: TaskPoolOption) => (
        <li {...props}>
          {option.translatedTitle}
          <span style={{ color: 'gray' }}>
            &nbsp;(<Trans>{option.docType}</Trans>)
          </span>
        </li>
      )}
      renderGroup={(params) => {
        const option = taskPoolOptions.find(
          (o) => o.categoryTitle === params.group
        );
        return (
          <li key={params.key}>
            <CategoryGroupHeader>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  height: '1.5em',
                  fontWeight: 'bold',
                }}
              >
                <div
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 8,
                    borderRadius: '50%',
                    backgroundColor: option?.categoryColor || '#000000',
                    display: 'inline-flex',
                  }}
                />
                {params.group}
              </div>
            </CategoryGroupHeader>
            <CategoryGroupItems>{params.children}</CategoryGroupItems>
          </li>
        );
      }}
    />
  );
}

// <Autocomplete
//   multiple
//   groupBy={(option) => option.categoryTitle}
//   id="phase-tasks-outlined"
//   options={taskPoolOptions}
//   getOptionKey={(option) => option.id}
//   getOptionLabel={(option) =>
//     option.title[language] ||
//     `${option.title[Object.keys(option.title)[0]]} [${
//       Object.keys(option.title)[0]
//     }]` ||
//     ''
//   }
//   value={phaseTasksValue}
//   isOptionEqualToValue={(option, value) => option.id === value.id}
//   onChange={onChangePhaseTasks}
//   renderInput={(params) => (
//     <TextField
//       {...params}
//       variant="outlined"
//       label={t('Tasks')}
//       placeholder={t('Add...')}
//     />
//   )}
//   renderGroup={(params) => {
//     const option = taskPoolOptions.find(
//       (o) => o.categoryTitle === params.group
//     );
//     return (
//       <li key={params.key}>
//         <CategoryGroupHeader>
//           <div
//             style={{
//               display: 'inline-flex',
//               alignItems: 'center',
//               backgroundColor: 'white',
//               height: '1.5em',
//               fontWeight: 'bold',
//             }}
//           >
//             <div
//               style={{
//                 width: 16,
//                 height: 16,
//                 marginRight: 8,
//                 borderRadius: '50%',
//                 backgroundColor: option?.categoryColor || '#000000',
//                 display: 'inline-flex',
//               }}
//             />
//             {params.group}
//           </div>
//         </CategoryGroupHeader>
//         <CategoryGroupItems>{params.children}</CategoryGroupItems>
//       </li>
//     );
//   }}
// />;
