import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Snackbar,
  AlertTitle,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import {
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Patient,
  TherapyCourse,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { useFirebaseUser } from '../../firebase/useFirebaseUser';
import usePatientCollection from '../../firebase/usePatientCollection';
import { useRefreshPatientStatistics } from '../../hooks/useRefreshPatientStatistics';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LoadingButton } from '@mui/lab';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import useRequestToViewPatientData from '../../firebase/useRequestToViewUserData';
import AddIcon from '@mui/icons-material/Add';
import { useCalculateStatisticsDataHash } from '../../hooks/useCalculateStatisticsDataHash';
import useStatisticsCacheDoc from '../../firebase/useStatisticsCacheDoc';
import { useMaxMarkingDate } from './useMaxMarkingDate';
import {
  customTabA11yProps,
  CustomTabPanel,
} from '../../components/CustomTabPanel';
import ProgressTab from './ProgressTab';
import StatisticsTableTab from './StatisticsTableTab';
import PatientPhaseTasks from './PatientPhaseTasks';
import DayGraphTab from './DayGraphTab';

type PatientViewProps = {
  therapyCourseId: string;
  therapyCoursePatientId: string;
  course: TherapyCourse;
  patient: Patient;
  phase: TherapyCoursePhase;
  currentStartDate: Timestamp | null;
  currentEndDate: Timestamp | null;
  setCurrentStartDate: (value: SetStateAction<Timestamp | null>) => void;
  setCurrentEndDate: (value: SetStateAction<Timestamp | null>) => void;
  phaseIndex: number;
};

export default function PatientView(props: PatientViewProps) {
  const { t } = useTranslation();
  const {
    patient,
    therapyCourseId,
    therapyCoursePatientId,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    course,
    phase,
    phaseIndex,
  } = props;

  const { email } = useFirebaseUser();

  const { useAppUser } = usePatientCollection();
  const [loading, setLoading] = useState(false);

  // const patientData = patientCollectionData?.find((p) => p.id === patient.id);
  const appUser = useAppUser(patient);

  const { sendRequest, requestSent, requestError, approved, denied } =
    useRequestToViewPatientData(patient, email);

  const statisticsHash = useCalculateStatisticsDataHash(
    therapyCoursePatientId || null,
    currentStartDate,
    currentEndDate
  );

  const { data: statisticsCache } = useStatisticsCacheDoc(
    statisticsHash,
    patient?.user?.id || null
  );

  const { refreshPatientStatistics, disabled: refreshDisabled } =
    useRefreshPatientStatistics(
      therapyCoursePatientId || null,
      currentStartDate || null,
      currentEndDate || null
    );

  useEffect(() => {
    if (approved) {
      refreshPatientStatistics(false);
    }
  }, [approved, refreshPatientStatistics]);

  const onClickRefreshStatistics = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    if (
      !patient.id ||
      refreshDisabled ||
      loading ||
      !refreshPatientStatistics ||
      !approved
    ) {
      return;
    }
    setLoading(true);
    refreshPatientStatistics(true)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    approved,
    loading,
    patient.id,
    refreshDisabled,
    refreshPatientStatistics,
  ]);

  const dateRange: [Dayjs | null, Dayjs | null] = useMemo(
    () => [
      currentStartDate ? dayjs(currentStartDate.toDate()) : null,
      currentEndDate ? dayjs(currentEndDate?.toDate()) : null,
    ],
    [currentStartDate, currentEndDate]
  );

  const statistics = useMemo(
    () => statisticsCache?.statistics || null,
    [statisticsCache?.statistics]
  );

  const fromDateStr = currentStartDate?.toDate().toISOString().substr(0, 10);
  const toDateStr = currentEndDate?.toDate().toISOString().substr(0, 10);

  const statisticsSlice = useMemo(
    () =>
      statistics?.filter(
        (statistic) =>
          (!fromDateStr ||
            new Date(statistic.marking_date) >= new Date(fromDateStr)) &&
          (!toDateStr ||
            new Date(statistic.marking_date) <= new Date(toDateStr))
      ) || [],
    [statistics, fromDateStr, toDateStr]
  );

  const maxMarkingDate = useMaxMarkingDate();
  const maxDateAsStr = maxMarkingDate.toISOString().substr(0, 10);

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  let responseMessage: JSX.Element | null = null;
  let responseSeverity: AlertColor = 'info';

  if (!approved && !denied) {
    responseMessage = requestSent ? (
      <Trans>
        Your request to view this patient data has been sent. Please wait for
        approval.
      </Trans>
    ) : (
      <Trans>You do not have permission to view this patient's data</Trans>
    );
  } else if (denied) {
    responseSeverity = 'error';
    responseMessage = (
      <Trans>Your request to view this patient's data has been denied.</Trans>
    );
  }

  if (!appUser) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Typography variant="h6" color={'textSecondary'}>
            {patient.remark} - {patient.licenceCode}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <LoadingButton
            variant="text"
            color="primary"
            loading={!!appUser?.statistics_cache_updating || loading}
            disabled={
              !!appUser?.statistics_cache_updating ||
              loading ||
              !approved ||
              refreshDisabled
            }
            onClick={onClickRefreshStatistics}
            startIcon={<RefreshIcon />}
          >
            {t('Refresh patient statistics')}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <PatientPhaseTasks
            email={email}
            course={course}
            phase={phase}
            phaseIndex={phaseIndex}
            therapyCourseId={therapyCourseId}
            therapyCoursePatientId={therapyCoursePatientId}
            appUser={appUser}
            approved={approved}
          />
        </Grid>
      </Grid>
      {responseMessage ? (
        <Alert severity={responseSeverity}>
          <Typography gutterBottom>{responseMessage}</Typography>
          {(!requestSent || denied) && (
            <LoadingButton
              onClick={() => sendRequest()}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              {t('Request Access')}
            </LoadingButton>
          )}
        </Alert>
      ) : (
        <>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <DateRangePicker
            value={dateRange}
            closeOnSelect
            onChange={(date) => {
              setCurrentStartDate(
                date[0] ? Timestamp.fromDate(date[0].toDate()) : null
              );
              setCurrentEndDate(
                date[1] ? Timestamp.fromDate(date[1].toDate()) : null
              );
            }}
          />
          {appUser?.statistics_cache_updating && (
            <Alert severity="info" sx={{ mt: 2 }}>
              {t('Statistics cache is updating')}
            </Alert>
          )}

          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label={t('Statistics Table')} {...customTabA11yProps(0)} />
              <Tab label={t('Progress Over Time')} {...customTabA11yProps(1)} />
              <Tab label={t('Day Graph')} {...customTabA11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={currentTab} index={0}>
            <StatisticsTableTab
              statisticsSlice={statisticsSlice}
              fromDateString={fromDateStr}
              toDateString={toDateStr}
              maxDateAsStr={maxDateAsStr}
            />
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={1}>
            <ProgressTab
              key={statisticsHash}
              statistics={statisticsSlice}
              fromDateString={fromDateStr}
              toDateString={toDateStr}
              maxDateAsStr={maxDateAsStr}
            />
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={2}>
            <DayGraphTab
              isUpdating={!!appUser?.statistics_cache_updating}
              statisticsSlice={statisticsSlice}
              fromDateString={fromDateStr}
              toDateString={toDateStr}
              maxDateAsStr={maxDateAsStr}
            />
          </CustomTabPanel>
        </>
      )}
      <Snackbar open={!!requestError}>
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error requesting access to patient data</Trans>
          </AlertTitle>
          <Trans>{requestError}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
