import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  AutocompleteValue,
  Snackbar,
  AlertTitle,
  Grid,
} from '@mui/material';
import {
  doc,
  updateDoc,
  DocumentReference,
  Timestamp,
} from 'firebase/firestore';
import { useCallback, useMemo, useState } from 'react';
import {
  Patient,
  TaskPoolTask,
  TherapyCourse,
  TherapyCoursePhase,
  User,
} from '../../firebase/firebaseModels';
import useUserProvider from '../../firebase/useUserProvider';
import {
  getCurrentLanguageCode,
  getTranslatableField,
} from '../../services/i18n';
import { useFirestore } from 'reactfire';
// import { useUpdatePatientCourses } from '../../firebase/useUpdatePatientCourses';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import usePortalUser from '../../firebase/usePortalUser';
import {
  TaskPoolOption,
  useTaskPoolOptions,
} from '../../hooks/useTaskPoolOptions';
import { TaskPoolSelector } from '../TherapyCourseEditor/TaskPoolSelector';
import { usePhaseTasksValue } from '../TherapyCourseEditor/usePhaseTasksValue';
import usePatientCollection from '../../firebase/usePatientCollection';

type PatientPhaseTasksProps = {
  email: string | null;
  course: TherapyCourse;
  phase: TherapyCoursePhase;
  phaseIndex: number;
  therapyCourseId: string;
  therapyCoursePatientId: string;
  appUser: User | null;
  approved: boolean | null;
};

export default function PatientPhaseTasks(props: PatientPhaseTasksProps) {
  const {
    course,
    phase,
    phaseIndex,
    email,
    therapyCoursePatientId,
    therapyCourseId,
    appUser,
    approved = false,
  } = props;

  const { t } = useTranslation();

  const { data: portalUserData } = usePortalUser();
  const { data: patientCollectionData } = usePatientCollection();

  const { data: userProviderData } = useUserProvider();
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  // const { updatePatientCourses, updateError, resetUpdateStatus } =
  //   useUpdatePatientCourses();

  const firestore = useFirestore();

  const defaultLanguage = course.defaultLanguage || getCurrentLanguageCode();
  const language = defaultLanguage;

  const therapyLanguages = useMemo(() => {
    let therapyLanguagesTmp = [...(portalUserData?.therapyLanguages || [])];
    if (!therapyLanguagesTmp.includes(defaultLanguage)) {
      therapyLanguagesTmp.push(defaultLanguage);
    }
    if (!therapyLanguagesTmp.includes(language)) {
      therapyLanguagesTmp.push(language);
    }
    return therapyLanguagesTmp;
  }, [portalUserData?.therapyLanguages, defaultLanguage, language]);

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const { status: taskPoolStatus, data: taskPoolData } = useTaskPoolCollection(
    null,
    therapyLanguages
  );

  const [updating, setUpdating] = useState(false);

  // console.log('appUser', appUser);
  const phasePatient = useMemo(() => {
    const pp = phase.patients?.find((p) => p.id === therapyCoursePatientId);
    if (!pp) {
      const patient = patientCollectionData?.find(
        (p) => p.id === therapyCoursePatientId
      );
      if (patient) {
        return {
          activeTasks: {},
          id: therapyCoursePatientId,
          patient: doc(
            firestore,
            'Patient',
            therapyCoursePatientId
          ) as DocumentReference<Patient>,
        };
      }
      return null;
    }
    return pp;
  }, [
    phase.patients,
    therapyCoursePatientId,
    patientCollectionData,
    firestore,
  ]);

  let patientActiveTasks = useMemo(
    () => phasePatient?.activeTasks || ({} as Record<string, boolean>),
    [phasePatient?.activeTasks]
  );
  const taskHistory = useMemo(() => appUser?.taskHistory || {}, [appUser]);
  const taskPoolOptions = useTaskPoolOptions(
    taskPoolData,
    categoryData,
    portalUserData
  );

  const phaseTasks = useMemo(() => phase.phaseTasks || [], [phase]);
  const phaseTasksValue: TaskPoolOption[] | null = usePhaseTasksValue(
    phaseTasks,
    taskPoolData,
    categoryData
  );

  // const phaseTasksValue = useMemo(
  //   () =>
  //     phaseTasks
  //       .map((task) => {
  //         const taskPoolTask = taskPoolData?.find((tpt) => tpt.id === task.id);
  //         const category = categoryData?.find(
  //           (cat) => cat.id === taskPoolTask?.category?.id
  //         );
  //         const firstLanguage = category?.title
  //           ? Object.keys(category?.title)[0]
  //           : null;
  //         const categoryTitle =
  //           category?.title[language] ||
  //           category?.title[firstLanguage || ''] ||
  //           null;
  //         const categoryColor = category?.color || null;

  //         return {
  //           id: taskPoolTask?.id || '',
  //           title: taskPoolTask?.title || { [language]: '' },
  //           categoryTitle: categoryTitle || t('Uncategorized'),
  //           categoryColor: categoryColor || '#000000',
  //         };
  //       })
  //       .filter((v) => v.id !== ''),
  //   [phaseTasks, taskPoolData, categoryData, language, t]
  // );
  const onChangePhaseTasks = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<
        {
          id: string;
          translatedTitle: string;
          categoryTitle: string;
          categoryColor: string;
        },
        true,
        false,
        false
      >
    ) => {
      // console.log('onChangePhaseTasks', {
      //   userProviderData,
      //   email,
      //   patientActiveTasks,
      //   phasePatient,
      //   props,
      //   course,
      //   patientCollectionData,
      // });
      if (
        !userProviderData?.id ||
        !email ||
        !patientActiveTasks ||
        !phasePatient ||
        !phase ||
        !course ||
        !patientCollectionData ||
        !therapyCourseId
      ) {
        return;
      }
      // console.log('onChangePhaseTasks', {
      //   newValue,
      // });
      // did we add a new task?

      const newTask = newValue.find(
        (v) => !phaseTasks.find((t) => t.id === v.id)
      );

      let newPhase = { ...phase };

      newPhase.phaseTasks = newValue.map(
        (v) =>
          doc(
            firestore,
            'TaskPool',
            v.id as string
          ) as DocumentReference<TaskPoolTask>
      );

      if (newTask?.id) {
        // new task default is disabled
        newPhase.patients = newPhase.patients?.map((p) => {
          return {
            ...p,
            activeTasks: {
              ...p.activeTasks,
              [newTask.id]: false,
            },
          };
        });
      }
      const newCourse = {
        id: therapyCourseId,
        ...course,
        phases: course.phases.map((ph, idx) =>
          idx === phaseIndex ? newPhase : ph
        ),
      };

      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        email,
        'TherapyCourse',
        therapyCourseId
      );
      setUpdating(true);
      updateDoc(therapyCourseRef, {
        phases: newCourse.phases,
      })
        .then(async () => {
          // if (course.patients) {
          //   await updatePatientCourses(newCourse, [...course.patients]);
          // }
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    },
    [
      userProviderData,
      email,
      patientActiveTasks,
      phasePatient,
      course,
      patientCollectionData,
      phase,
      therapyCourseId,
      firestore,
      phaseTasks,
      phaseIndex,
    ]
  );

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, taskId: string) => {
      // console.log('handleCheckboxChange', {
      //   userProviderData,
      //   email,
      //   patientActiveTasks,
      //   phasePatient,
      //   phase,
      //   course,
      //   patientCollectionData,
      // });

      if (
        !userProviderData?.id ||
        !email ||
        !patientActiveTasks ||
        !phasePatient ||
        !phase ||
        !course ||
        !patientCollectionData ||
        !therapyCourseId
      ) {
        return;
      }

      const checked = e.target.checked;

      const newActiveTasks = { ...patientActiveTasks, [taskId]: checked };
      const newPhasePatient = {
        ...phasePatient,
        activeTasks: newActiveTasks,
      };
      // console.log('newPhasePatient', newPhasePatient);
      const newPhasePatients = [
        ...phase.patients.filter((p) => p.id !== therapyCoursePatientId),
        newPhasePatient,
      ];
      const newPhase = {
        ...phase,
        patients: newPhasePatients,
      };
      const newCourse = {
        id: therapyCourseId,
        ...course,
        phases: course.phases.map((ph, idx) =>
          ph.id === newPhase.id ? newPhase : ph
        ),
      };
      // console.log('newCourse', newCourse);
      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        email,
        'TherapyCourse',
        therapyCourseId
      );
      setUpdating(true);
      updateDoc(therapyCourseRef, {
        phases: newCourse.phases,
      })
        .then(async () => {
          // console.log('newCourse', newCourse);
          // console.log('course.patients', course.patients);
          // if (course.patients) {
          //   await updatePatientCourses(newCourse, [...course.patients]);
          // }
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    },
    [
      userProviderData,
      email,
      patientActiveTasks,
      phasePatient,
      phase,
      course,
      patientCollectionData,
      therapyCourseId,
      firestore,
      therapyCoursePatientId,
    ]
  );

  if (
    categoryStatus === 'loading' ||
    taskPoolStatus === 'loading' ||
    !taskPoolOptions
  ) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        <Trans>Tasks for this Phase</Trans>
      </Typography>
      <TaskPoolSelector
        taskPoolOptions={taskPoolOptions}
        phaseTasksValue={phaseTasksValue}
        onChangePhaseTasks={onChangePhaseTasks}
      />
      <Grid container sx={{ mt: 2 }}>
        {taskPoolData &&
          phaseTasks.map((task) => {
            // console.log('updating', updating);
            const taskId = task.id;
            const taskPoolTask = taskPoolData.find((tpt) => tpt.id === taskId);
            const { log } = taskHistory[taskId] || { log: [] };
            const lastCompletedTime = log
              .filter((l) => l.phaseId === phase.id)
              .reduce<Timestamp | null>((acc, cur) => {
                if (!acc || cur.completedTime.toDate() > acc.toDate()) {
                  return cur.completedTime;
                }
                return acc;
              }, null);
            const taskLastDoneStr = lastCompletedTime
              ? lastCompletedTime.toDate().toLocaleDateString()
              : '';
            return taskPoolTask ? (
              <>
                <Grid item xs={6} key={taskId}>
                  <FormGroup key={taskId} row>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={updating}
                          checked={
                            taskId in patientActiveTasks
                              ? patientActiveTasks[taskId]
                              : true
                          }
                          onChange={(e) => handleCheckboxChange(e, taskId)}
                        />
                      }
                      label={getTranslatableField(taskPoolTask.title)}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  {approved ? (
                    taskLastDoneStr ? (
                      <Typography variant="body1" color="textSecondary">
                        {t('Done')}: {taskLastDoneStr}
                      </Typography>
                    ) : (
                      <Typography variant="body1" color="error">
                        {t('Not done')}
                      </Typography>
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </>
            ) : null;
          })}
      </Grid>
      <Snackbar
        open={updateSuccessful}
        autoHideDuration={6000}
        onClose={() => setUpdateSuccessful(false)}
      >
        <Alert onClose={() => setUpdateSuccessful(false)} severity="success">
          <Trans>Patient data updated</Trans>
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!collectionError}
        autoHideDuration={6000}
        onClose={() => setUpdateSuccessful(false)}
      >
        <Alert onClose={() => setUpdateSuccessful(false)} severity="error">
          <AlertTitle>
            <Trans>Error fetching task pool data</Trans>
          </AlertTitle>
          <Trans>{collectionError?.message}</Trans>
        </Alert>
      </Snackbar>
      {/* <Snackbar
        open={!!updateError}
        autoHideDuration={6000}
        onClose={resetUpdateStatus}
      >
        <Alert onClose={resetUpdateStatus} severity="error">
          <AlertTitle>
            <Trans>Error updating patient data</Trans>
          </AlertTitle>
          <Trans>{updateError?.message}</Trans>
        </Alert>
      </Snackbar> */}
    </>
  );
}
