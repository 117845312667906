import { useTranslation, Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { doc, updateDoc } from 'firebase/firestore';
import {
  Alert,
  AlertTitle,
  CardHeader,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import usePatientDoc from '../firebase/usePatientDoc';
import { useFirebaseUser } from '../firebase/useFirebaseUser';

export default function PatientEditor() {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId: string }>();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();
  const [updateError, setUpdateError] = useState<string | null>(null);

  const [licenceCode, setLicenceCode] = useState('');
  const [remark, setRemark] = useState('');
  // const [userTasks, setUserTasks] = useState<UserTask[]>([]);

  const [updateSuccessful, setUpdateSuccessful] = useState<boolean>(false);

  const { status, error, data } = usePatientDoc(patientId as string);
  const [updating, setUpdating] = useState(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);

  useEffect(() => {
    if (status === 'success' && data) {
      setLicenceCode(data.licenceCode || '');
      setRemark(data.remark || '');
    }
  }, [status, data]);

  const handleSave = useCallback(async () => {
    if (!patientId || !userProviderData?.id || !email) {
      return;
    }

    setUpdating(true);

    if (patientId === 'new') {
      // this should not happen, show error alert
      setErrorAlertVisible(true);
      setUpdating(false);
      return;
    } else {
      updateDoc(
        doc(
          firestore,
          'ServiceProvider',
          userProviderData.id,
          'UserData',
          email,
          'Patient',
          patientId
        ),
        {
          licenceCode,
          remark,
        }
      )
        .then(() => {
          setUpdateSuccessful(true);
          setUpdating(false);
        })
        .catch((error) => {
          console.error('Error updating patient', error);
          setUpdateError(error.message);
          setUpdating(false);
        });
    }
  }, [patientId, userProviderData?.id, email, firestore, licenceCode, remark]);

  if (status === 'loading' || userProviderStatus === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('Error loading patient')}</Alert>;
  }

  if (userProviderStatus === 'error') {
    return <Alert severity="error">{t('Error loading user provider')}</Alert>;
  }

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardHeader title={t('Basics')} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('License Code')}
                placeholder={t('Enter License Code')}
                disabled
                value={licenceCode}
                onChange={(e) => setLicenceCode(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Personal Identifier')}
                placeholder={t('Enter Personal Identifier')}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {error && <Alert severity="error">{t('Error loading patient')}</Alert>}
      {updateSuccessful && (
        <Alert severity="success" onClose={() => setUpdateSuccessful(false)}>
          {t('Patient updated successfully')}
        </Alert>
      )}
      {errorAlertVisible && (
        <Alert severity="error" onClose={() => setErrorAlertVisible(false)}>
          <AlertTitle>{t('Error')}</AlertTitle>
          {t('Invalid patient id')}
        </Alert>
      )}
      <LoadingButton
        sx={{ mt: 2 }}
        loading={updating}
        onClick={handleSave}
        variant="contained"
        color="primary"
      >
        <Trans>Save</Trans>
      </LoadingButton>
      <Snackbar open={!!updateError}>
        <Alert severity="error">
          <AlertTitle>{t('Error')}</AlertTitle>
          <Trans>{updateError}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
