import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import { CardHeader, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import TranslatableEditor from './TranslatableEditor';
import TranslatableTextField from './TranslatableTextField';

type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask question section.
 */
const TaskPoolTaskURLSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });
    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });

    useEffect(() => {
      if (section.title) {
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [section]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'question' as TaskPoolTaskSectionType,
      async save() {
        let newSection = {
          ...section,
          title,
          description,
        };
        return { success: true, message: title[language], newSection };
      },
    }));

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    return (
      <Card variant="outlined">
        <CardHeader
          title={t('Free Text Question')}
          action={
            <IconButton onClick={onDeleteSection} disabled={disabled}>
              <DeleteIcon />
            </IconButton>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`question-${section.id}-title-${language}`}
            disabled={disabled}
            label={t('Question Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language')
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`question-${section.id}-description-${language}`}
            disabled={disabled}
            placeholder={t('Question Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskURLSectionEditor;
