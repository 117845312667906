import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import {
  Alert,
  CardActions,
  CardHeader,
  CardMedia,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import useUserProvider from '../firebase/useUserProvider';
import { SaveFuncParams, SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import { useProgressiveUpload } from '../hooks/useProgressiveUpload';
import TranslatableEditor from './TranslatableEditor';
import TranslatableTextField from './TranslatableTextField';
import { useDeleteObjectFromStorage } from '../hooks/useDeleteObjectFromStorage';

type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask PDF section.
 */
const TaskPoolTaskPDFSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const { data: userProviderData, status: userProviderStatus } =
      useUserProvider();

    const [pdfFile, setPDFFile] = useState<File | null>(null);
    const [pdfUrl, setPDFUrl] = useState<string | null>(null);

    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });

    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });

    useEffect(() => {
      if (section.url) {
        setPDFUrl(section.url);
        setPDFFile(null);
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [section]);

    const {
      progressiveUpload,
      error: uploadError,
      progress,
    } = useProgressiveUpload(pdfFile, section.id);

    const { deleteObjectFromStorage } = useDeleteObjectFromStorage();

    const saveFunc = useCallback(async (): Promise<SaveFuncParams> => {
      if (!pdfFile) {
        return {
          success: false,
          message: 'No file selected',
        };
      }
      if (!userProviderData?.id) {
        return {
          success: false,
          message: 'No user provider data',
        };
      }

      const url = await progressiveUpload();

      if (!url) {
        return {
          success: false,
          message: 'Error uploading pdf',
          error: new Error('Error uploading pdf'),
        };
      }
      const newSection = {
        ...section,
        title,
        description,
        url,
      };

      return { success: true, message: url, newSection };
    }, [
      pdfFile,
      userProviderData?.id,
      progressiveUpload,
      section,
      title,
      description,
    ]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'pdf' as TaskPoolTaskSectionType,
      save: saveFunc,
    }));

    const handleFileInputChange = useCallback(
      (file: File | null) => {
        setPDFFile(file);
        // if title is missing in primary language, set it to the file name
        if (!title[defaultLanguage]) {
          setTitle({
            ...title,
            [defaultLanguage]: file?.name || '',
          });
        }
      },
      [defaultLanguage, title]
    );

    // const deletePDFFile = useCallback(() => {
    //   const fileName = getFilenameFromUrl(pdfUrl);
    //   if (!fileName) {
    //     return;
    //   }
    //   deleteObjectFromStorage(fileName);
    //   setPDFUrl(null);
    //   setPDFFile(null);
    // }, [pdfUrl, deleteObjectFromStorage]);

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    const downloadPDFFile = useCallback(() => {
      if (pdfUrl) {
        window.open(pdfUrl, '_blank')?.focus();
      }
    }, [pdfUrl]);

    if (userProviderStatus === 'loading') {
      return <CircularProgress />;
    }

    return (
      <Card variant="outlined">
        <CardHeader
          title={t('PDF')}
          action={
            <IconButton onClick={onDeleteSection} disabled={disabled}>
              <DeleteIcon />
            </IconButton>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`pdf-title-${language}-${section.id}`}
            disabled={disabled}
            label={t('PDF Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language') +
                  ' ' +
                  `(${defaultLanguage})`
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`pdf-description-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('PDF Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />
          {pdfUrl && (
            <Card
              sx={{
                mt: 2,
                maxWidth: 345,
              }}
              key={pdfUrl}
            >
              <CardMedia component="iframe" src={pdfUrl} />
              <CardActions>
                <IconButton onClick={downloadPDFFile}>
                  <DownloadIcon />
                </IconButton>
              </CardActions>
            </Card>
          )}
          {progress !== null && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 2 }}
            />
          )}
          {uploadError && (
            <Alert severity="error">{t(uploadError.message)}</Alert>
          )}
          <Box sx={{ mt: 2 }}>
            <MuiFileInput
              id="pdf"
              label={t('Upload PDF')}
              onChange={handleFileInputChange}
              inputProps={{ accept: 'application/pdf' }}
              value={pdfFile}
              fullWidth
              disabled={progress !== null || disabled}
              clearIconButtonProps={{
                title: 'Remove',
                children: <CloseIcon fontSize="small" />,
              }}
            />
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskPDFSectionEditor;
