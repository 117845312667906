import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useState, useCallback } from 'react';
import { useStorage } from 'reactfire';
import { DEFAULT_IMAGE_URL } from '../consts';
import useUserProvider from '../firebase/useUserProvider';

export function useUpload(file: File | null, basePath: string) {
  const [publicUrl, setPublicUrl] = useState<string>(DEFAULT_IMAGE_URL);

  const storage = useStorage();
  const { data: userProviderData } = useUserProvider();

  const uploadFile = useCallback(
    (callback?: (url: string | null) => void) => {
      if (!userProviderData?.id) {
        return;
      }
      if (file) {
        const imageRef = ref(
          storage,
          `${userProviderData?.id}/${basePath}/${file.name}`
        );
        uploadBytes(imageRef, file).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            setPublicUrl(url);
            callback && callback(url);
          });
        });
      } else {
        callback && callback(null);
      }
    },
    [basePath, file, storage, userProviderData?.id]
  );

  return { publicUrl, uploadFile };
}
