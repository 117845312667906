import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Box, useTheme, Grid } from '@mui/material';
import { useMemo } from 'react';
import { StatisticDay } from './types';
import { ChartsXAxisProps, LineChart } from '@mui/x-charts';

type ProgressTabProps = {
  statistics: StatisticDay[] | null;
  fromDateString?: string;
  toDateString?: string;
  maxDateAsStr: string;
};

function formatPercent(value: number) {
  return `${value.toFixed(2)} %`;
}

export default function ProgressTab(props: ProgressTabProps) {
  const { t } = useTranslation();
  const {
    statistics: statisticsSlice,
    fromDateString,
    toDateString,
    maxDateAsStr,
  } = props;
  const theme = useTheme();

  let firstIndexWithData = statisticsSlice?.findIndex(
    (item) =>
      item.sleep_efficiency_moving_average !== null ||
      item.completed_tasks_moving_average !== null
  );

  if (firstIndexWithData && firstIndexWithData > 0) {
    firstIndexWithData--;
  }
  // chop off everything until first date with data
  const statistics = statisticsSlice?.slice(firstIndexWithData);

  const { xAxis, series, yAxis } = useMemo(() => {
    if (statistics) {
      const xAxisData = statistics.map((item, idx) => idx);
      const xAxis = [
        {
          type: t('time'),
          data: xAxisData,
          scaleType: 'point',
          valueFormatter: (value: number) => {
            const date = statistics
              ? new Date(statistics[value]?.marking_date)
              : null;
            return date?.toLocaleDateString('fi-FI') || '';
          },
          tickLabelFormatter: (value: number) => {
            const date = statistics
              ? new Date(statistics[value]?.marking_date)
              : null;
            return date?.toLocaleDateString('fi-FI') || '';
          },
        } as ChartsXAxisProps,
      ];
      const series = [
        {
          name: t('Sleep Efficiency (Moving Average)'),
          data: statistics.map((item) => item.sleep_efficiency_moving_average),
          color: theme.palette.primary.main,
          valueFormatter: (value: number | null) =>
            value === null
              ? t('No data')
              : `${t('Sleep Efficiency')} ${formatPercent(value)}`,
        },
        {
          name: t('Task Completion Percentage (Moving Average)'),
          data: statistics.map((item) =>
            item.completed_tasks_moving_average !== null
              ? item.completed_tasks_moving_average * 100.0
              : null
          ),
          color: theme.palette.secondary.main,
          valueFormatter: (value: number | null) =>
            value === null
              ? t('No data')
              : `${t('Task Completion Percentage')} ${formatPercent(value)}`,
        },
      ];
      const yAxis = [
        {
          min: 0,
          max: 100,
        },
      ];
      return { xAxis, series, yAxis };
    }
    return { xAxis: [], series: [], yAxis: [] };
  }, [statistics, t, theme.palette.primary.main, theme.palette.secondary.main]);

  const legendItems = useMemo(() => {
    return series.map((item) => ({
      label: item.name,
      color: item.color,
    }));
  }, [series]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setDimensions({ width, height: width * 0.75 }); // 4:3 aspect ratio
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  if (!statistics) {
    return <div>No data</div>;
  }

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <Typography variant="h5" component="h1" gutterBottom>
        <Trans>Progress Over Time</Trans>
      </Typography>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <LineChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={series}
          width={dimensions.width}
          height={dimensions.height}
          sx={{
            aspectRatio: '4/3',
            width: '100%',
            height: 'auto',
          }}
          slotProps={{
            legend: {
              hidden: true, // Hide the built-in legend
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          {legendItems.map((item, index) => (
            <Grid item key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: item.color,
                    marginRight: 1,
                  }}
                />
                <Typography variant="body2">{item.label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
