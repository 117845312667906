import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import {
  Alert,
  CardActions,
  CardHeader,
  CardMedia,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import useUserProvider from '../firebase/useUserProvider';
import { SaveFuncParams, SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import { useProgressiveUpload } from '../hooks/useProgressiveUpload';
import TranslatableEditor from './TranslatableEditor';
import TranslatableTextField from './TranslatableTextField';
import { useDeleteObjectFromStorage } from '../hooks/useDeleteObjectFromStorage';

type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask Image section.
 */
const TaskPoolTaskImageSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const { data: userProviderData, status: userProviderStatus } =
      useUserProvider();

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });

    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });

    useEffect(() => {
      if (section.url) {
        setImageUrl(section.url);
        setImageFile(null);
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [section]);

    const {
      progressiveUpload,
      error: uploadError,
      progress,
    } = useProgressiveUpload(imageFile, section.id);

    const { deleteObjectFromStorage } = useDeleteObjectFromStorage();

    const saveFunc = useCallback(async (): Promise<SaveFuncParams> => {
      if (!imageFile) {
        return {
          success: false,
          message: 'No file selected',
        };
      }
      if (!userProviderData?.id) {
        return {
          success: false,
          message: 'No user provider data',
        };
      }

      const url = await progressiveUpload();

      if (!url) {
        return {
          success: false,
          message: 'Error uploading image',
          error: new Error('Error uploading image'),
        };
      }
      const newSection = {
        ...section,
        title,
        description,
        url,
      };

      return { success: true, message: url, newSection };
    }, [
      imageFile,
      userProviderData?.id,
      progressiveUpload,
      section,
      title,
      description,
    ]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'image' as TaskPoolTaskSectionType,
      save: saveFunc,
    }));

    const handleFileInputChange = useCallback(
      (file: File | null) => {
        setImageFile(file);
        // if title is missing in primary language, set it to the file name
        if (!title[defaultLanguage]) {
          setTitle({
            ...title,
            [defaultLanguage]: file?.name || '',
          });
        }
      },
      [defaultLanguage, title]
    );

    // const deleteImageFile = useCallback(() => {
    //   const fileName = getFilenameFromUrl(imageUrl);
    //   if (!fileName) {
    //     return;
    //   }
    //   deleteObjectFromStorage(fileName);
    //   setImageUrl(null);
    //   setImageFile(null);
    // }, [imageUrl, deleteObjectFromStorage]);

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    const downloadImageFile = useCallback(() => {
      if (imageUrl) {
        window.open(imageUrl, '_blank')?.focus();
      }
    }, [imageUrl]);

    if (userProviderStatus === 'loading') {
      return <CircularProgress />;
    }

    return (
      <Card variant="outlined">
        <CardHeader
          title={t('Image')}
          action={
            <IconButton onClick={onDeleteSection} disabled={disabled}>
              <DeleteIcon />
            </IconButton>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`image-title-${language}-${section.id}`}
            disabled={disabled}
            label={t('Image Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language') +
                  ' ' +
                  `(${defaultLanguage})`
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`image-description-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('Image Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />
          {imageUrl && (
            <Card
              sx={{
                mt: 2,
                maxWidth: 345,
              }}
              key={imageUrl}
            >
              <CardMedia
                component="img"
                height="140"
                image={imageUrl}
                alt={title[language]}
              />
              <CardActions>
                <IconButton onClick={downloadImageFile}>
                  <DownloadIcon />
                </IconButton>
              </CardActions>
            </Card>
          )}
          {progress !== null && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 2 }}
            />
          )}
          {uploadError && (
            <Alert severity="error">{t(uploadError.message)}</Alert>
          )}

          <Box sx={{ mt: 2 }}>
            <MuiFileInput
              id={`image-file-${language}-${section.id}`}
              label={t('Upload Image')}
              onChange={handleFileInputChange}
              inputProps={{ accept: 'image/*' }}
              value={imageFile}
              fullWidth
              disabled={progress !== null || disabled}
              clearIconButtonProps={{
                title: 'Remove',
                children: <CloseIcon fontSize="small" />,
              }}
            />
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskImageSectionEditor;
