import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { PortalUser } from './firebaseModels';
import useUserProvider from './useUserProvider';
import { useFirebaseUser } from './useFirebaseUser';
import { FirebaseError } from 'firebase/app';
import { useTranslation } from 'react-i18next';

type TPortalUserData = {
  status: 'loading' | 'success' | 'error';
  data: PortalUser | null;
  error: FirebaseError | null;
};

/**
 * Custom hook for getting a PortalUser from Firestore.
 * @param id The ID of the PortalUser to fetch.
 * @returns TPortalUserData
 */
export default function usePortalUserDoc(id: string | null): TPortalUserData {
  const firestore = useFirestore();

  const { email } = useFirebaseUser();
  const { data: userProviderData } = useUserProvider();
  const { i18n } = useTranslation();

  const [status, setStatus] = useState<TPortalUserData['status']>('loading');
  const [data, setData] = useState<PortalUser | null>(null);
  const [error, setError] = useState<TPortalUserData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (!userProviderData?.id || !email || !id) {
      return;
    }

    if (id === 'new') {
      const language = i18n.language === 'fi' ? 'fi' : 'en';
      setStatus('success');
      setData({
        name: '',
        phone: '',
        email: '',
        level: 'user',
        language,
        therapyLanguages: [],
        created: Timestamp.now(),
        createdBy: email,
        modified: Timestamp.now(),
        modifiedBy: email,
      });
      return;
    }

    const portalUserRef = doc(firestore, 'PortalUser', id);
    const unsubscribe = onSnapshot(
      portalUserRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          setData(doc.data() as PortalUser);
          setStatus('success');
        } else {
          setStatus('error');
          setError(new FirebaseError('PortalUser not found', 'not-found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error);

        console.error('Error getting PortalUser snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('usePortalUserDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [firestore, id, userProviderData?.id, nTimeouts, email, i18n.language]);

  return { status, error, data };
}
