import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback, useRef, useState } from 'react';
import { useCalculateStatisticsDataHash } from './useCalculateStatisticsDataHash';
import usePatientCollection from '../firebase/usePatientCollection';
import debounce from 'lodash/debounce';

const DISABLE_TIMEOUT = 10000; // ten seconds
const DEBOUNCE_DELAY = 3000; // 3 seconds

export function useRefreshPatientStatistics(
  therapyCoursePatientId: string | null,
  startDate: Timestamp | null,
  endDate: Timestamp | null
) {
  const {
    data: patientCollectionData,
    usePatient,
    useAppUser,
  } = usePatientCollection();
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);
  const [disabled, setDisabled] = useState(false);

  const functions = useFunctions();
  const getStatistics = httpsCallable(functions, 'getstatistics');
  const statisticsHash = useCalculateStatisticsDataHash(
    therapyCoursePatientId,
    startDate,
    endDate
  );

  const patient = usePatient(therapyCoursePatientId);
  const appUser = useAppUser(patient);

  const debouncedRefreshRef = useRef<ReturnType<typeof debounce>>();

  const refreshPatientStatistics = useCallback(
    async (forceRefresh = false) => {
      if (
        !therapyCoursePatientId ||
        !statisticsHash ||
        !patientCollectionData ||
        !patient?.user ||
        !appUser
      ) {
        return;
      }

      if (!!appUser.statistics_cache_updating) {
        return;
      }

      const lastStatisticsCacheDate = appUser.statisticsCacheDate
        ? appUser.statisticsCacheDate[statisticsHash]
        : null;

      if (
        !forceRefresh &&
        lastStatisticsCacheDate &&
        lastStatisticsCacheDate.toMillis() > Date.now() - 1000 * 60 * 60
      ) {
        return;
      }

      if (debouncedRefreshRef.current) {
        debouncedRefreshRef.current.cancel();
      }

      debouncedRefreshRef.current = debounce(async () => {
        // refresh statistics
        setLastRefresh(new Date());
        setDisabled(true);
        setTimeout(() => {
          setDisabled(false);
        }, DISABLE_TIMEOUT);
        try {
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          await getStatistics({
            userId: appUser.id,
            forceRefresh,
            timezone,
            statisticsHash,
            startDate: startDate?.toDate().toISOString(),
            endDate: endDate?.toDate().toISOString(),
          });
        } catch (error) {
          console.error('Error refreshing patient statistics', error);
        }
      }, DEBOUNCE_DELAY);

      debouncedRefreshRef.current();
    },
    [
      endDate,
      getStatistics,
      patient,
      patientCollectionData,
      startDate,
      statisticsHash,
      therapyCoursePatientId,
      appUser,
    ]
  );

  return { refreshPatientStatistics, lastRefresh, disabled };
}
