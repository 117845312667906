import { useTranslation, Trans } from 'react-i18next';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TranslatableField } from '../firebase/firebaseModels';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslationSuggestion } from '../firebase/useTranslationSuggestion';

type TranslatableTextFieldProps = {
  disabled?: boolean;
  label: string;
  language: string;
  type: 'title' | 'description';
  setValue?: Dispatch<SetStateAction<TranslatableField>>;
  value: TranslatableField;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  sx?: any;
  size?: 'small' | 'medium';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function TranslatableTextField(
  props: TranslatableTextFieldProps
) {
  const { t } = useTranslation();
  const {
    disabled,
    label,
    helperText,
    error,
    language,
    type,
    setValue,
    value,
    placeholder,
    sx,
    onChange,
    size,
  } = props;

  const [fetchTranslation, setFetchTranslation] = useState(false);

  const {
    loading: trsLoading,
    canFetch,
    translationSuggestion,
    fieldInDefaultLanguage,
    defaultLanguage,
    defaultLanguageName,
    error: trsError,
  } = useTranslationSuggestion(value, language, type, fetchTranslation);

  useEffect(() => {
    if (trsError) {
      setFetchTranslation(false);
    }
  }, [trsError]);

  const translatedValue = value[language] || '';
  const hasValue = !!translatedValue;

  const placeholderText = useMemo(
    () =>
      translationSuggestion
        ? `${t('Translation suggestion')}: ${translationSuggestion}`
        : fieldInDefaultLanguage && defaultLanguageName
        ? `${t(defaultLanguageName)}: ${fieldInDefaultLanguage}`
        : placeholder,
    [
      translationSuggestion,
      t,
      fieldInDefaultLanguage,
      defaultLanguageName,
      placeholder,
    ]
  );

  const defaultOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (translatedValue !== value) {
        setValue &&
          setValue((val) => ({
            ...val,
            [language]: value,
          }));
      }
    },
    [language, setValue, translatedValue]
  );

  const selectedOnChange = onChange || defaultOnChange;

  const inputProps = useMemo(
    () => ({
      endAdornment: !disabled && (
        <InputAdornment position="end">
          {!hasValue &&
            !translationSuggestion &&
            canFetch &&
            defaultLanguage !== language && (
              <Button
                onClick={() => {
                  setFetchTranslation(true);
                }}
              >
                <Trans>Fetch Translation Suggestion</Trans>
              </Button>
            )}
          {fetchTranslation && trsLoading && <CircularProgress size={20} />}
          {!trsLoading && translationSuggestion && (
            <Button
              onClick={() => {
                selectedOnChange({
                  target: { value: translationSuggestion },
                } as any);
              }}
            >
              <Trans>Use Suggestion</Trans>
            </Button>
          )}
        </InputAdornment>
      ),
    }),
    [
      disabled,
      hasValue,
      translationSuggestion,
      canFetch,
      defaultLanguage,
      language,
      fetchTranslation,
      trsLoading,
      selectedOnChange,
    ]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TextField
        disabled={disabled}
        label={label}
        placeholder={placeholderText}
        InputProps={inputProps}
        value={translatedValue}
        onChange={selectedOnChange}
        fullWidth
        error={error}
        helperText={helperText}
        InputLabelProps={{ shrink: true }}
        sx={sx}
        size={size}
      />
      {trsError && (
        <Alert severity="error" variant="outlined">
          <AlertTitle>{t(trsError.code)}</AlertTitle>
          {t((trsError.customData?.message as string) || trsError.message)}
        </Alert>
      )}
    </Box>
  );
}
