import { Trans, useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useFirestore } from 'reactfire';
import usePortalUser from '../firebase/usePortalUser';
import { CircularProgress, TextField } from '@mui/material';
import useUserProvider from '../firebase/useUserProvider';
import Autocomplete, { AutocompleteValue } from '@mui/material/Autocomplete';
import { doc, updateDoc } from 'firebase/firestore';
import { useCallback, useMemo, useState } from 'react';
import {
  THERAPY_LANGUAGE_OPTIONS,
  UI_LANGUAGE_OPTIONS,
} from '../services/i18n';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { PortalUser } from '../firebase/firebaseModels';

/**
 * UserData component for displaying the current user's data on the Settings page.
 * @component
 * @returns JSX.Element
 */
export function UserData() {
  const { t, i18n } = useTranslation();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { data: portalUserData, status: portalUserStatus } = usePortalUser();
  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();
  const [languageSelectDisabled, setLanguageSelectDisabled] = useState(false);
  const [therapyLanguagesSelectDisabled, setTherapyLanguagesSelectDisabled] =
    useState(false);

  const userLanguage = useMemo(
    () => portalUserData?.language || i18n.language,
    [i18n.language, portalUserData?.language]
  );

  const portalUserTherapyLanguages = useMemo(
    () => [...(portalUserData?.therapyLanguages || ['en', 'fi', 'sv'])],
    [portalUserData?.therapyLanguages]
  );

  const updatePortalUser = useCallback(
    async (updateData: Partial<PortalUser>) => {
      if (!email) {
        return;
      }
      const portalUserRef = doc(
        firestore,
        'PortalUser',

        email
      );
      await updateDoc(portalUserRef, updateData);
    },
    [email, firestore]
  );

  const languageValue = useMemo(
    () => UI_LANGUAGE_OPTIONS.find((l) => l.code === userLanguage),
    [userLanguage]
  );

  const therapyLanguagesValue = useMemo(
    () =>
      THERAPY_LANGUAGE_OPTIONS.filter((l) =>
        portalUserTherapyLanguages.includes(l.code)
      ).map((l) => ({
        code: l.code,
        name: t(l.name),
      })),
    [t, portalUserTherapyLanguages]
  );

  const handleLanguageChange = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<
        { code: string; name: string },
        false,
        true,
        false
      >
    ) => {
      if (newValue) {
        setLanguageSelectDisabled(true);
        updatePortalUser({ language: newValue.code })
          .then(() => i18n.changeLanguage(newValue.code))
          .catch((error) => {
            console.error('Error updating user document: ', error);
          })
          .finally(() => {
            setLanguageSelectDisabled(false);
          });
      }
    },
    [i18n, updatePortalUser]
  );

  const handleTherapyLanguagesChange = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<
        { code: string; name: string },
        false,
        true,
        false
      >[]
    ) => {
      if (newValue) {
        console.log('newValue', newValue);
        setTherapyLanguagesSelectDisabled(true);
        updatePortalUser({ therapyLanguages: newValue.map((l) => l.code) })
          .then(() => {
            // no-op
          })
          .catch((error) => {
            console.error('Error updating user document: ', error);
          })
          .finally(() => {
            setTherapyLanguagesSelectDisabled(false);
          });
      }
    },
    [updatePortalUser]
  );

  const therapyLanguageOptions = useMemo(
    () =>
      THERAPY_LANGUAGE_OPTIONS.map((l) => ({
        code: l.code,
        name: t(l.name),
      })),
    [t]
  );

  if (portalUserStatus === 'loading' || userProviderStatus === 'loading') {
    return <CircularProgress />;
  }
  return (
    <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        <Trans>User Data</Trans>
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="user data">
          <TableBody>
            <TableRow>
              <TableCell variant="head">{t('Name')}</TableCell>
              <TableCell>{portalUserData?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Provider')}</TableCell>
              <TableCell>{userProviderData?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Email')}</TableCell>
              <TableCell>{email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Autocomplete
        sx={{ mt: 2, width: 280 }}
        options={UI_LANGUAGE_OPTIONS}
        disabled={languageSelectDisabled}
        blurOnSelect
        disableClearable
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        value={languageValue}
        onChange={handleLanguageChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            label={t('UI Language')}
            placeholder={t('Select UI Language')}
          />
        )}
      />
      <Autocomplete
        sx={{ mt: 2 }}
        options={therapyLanguageOptions}
        disabled={therapyLanguagesSelectDisabled}
        multiple
        blurOnSelect
        filterSelectedOptions
        disableClearable
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        value={therapyLanguagesValue}
        onChange={handleTherapyLanguagesChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            label={t('Therapy Languages')}
            placeholder={t('Select Therapy Languages')}
          />
        )}
      />
    </Paper>
  );
}
