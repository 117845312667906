import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Paper, Typography } from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { getTranslatableField } from '../services/i18n';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PlayIcon from '@mui/icons-material/PlayArrow';
import LinkIcon from '@mui/icons-material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState } from 'react';
import { deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { TherapyCourse } from '../firebase/firebaseModels';
import Alert from '@mui/material/Alert';
import { htmlToText } from 'html-to-text';
import useTherapyCourseCollection from '../firebase/useTherapyCourseCollection';
import useUserProvider from '../firebase/useUserProvider';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
// import { useCleanPatientCourses } from '../firebase/useCleanPatientCourses';

type TherapyCoursesProps = {
  therapycoursetype: 'individual' | 'group' | 'all';
};
export default function TherapyCourses(props: TherapyCoursesProps) {
  const { t } = useTranslation();
  const { therapycoursetype } = props;
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const {
    data: userProviderData,
    status: userProviderStatus,
    error: userProviderError,
  } = useUserProvider();

  // const [therapyPlanId, setTherapyPlanId] = useState<string | null>(null);

  const [showActiveOnly, setShowActiveOnly] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { status, data } = useTherapyCourseCollection(
    therapycoursetype,
    showActiveOnly
  );
  // console.log('therapycoursetype', therapycoursetype);
  // const { status: planCollection, data: planData } = useTherapyPlanCollection();
  // const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const handleDelete = useCallback(
    (TherapyCourseId: string, TherapyCourseTitle: string) => {
      setDeleteId(TherapyCourseId);
      setDialogTitle(t('Delete Therapy Course') + ' ' + TherapyCourseTitle);
      setDialogContent(
        t('Are you sure you want to delete this therapy course?')
      );
      setDialogOpen(true);
    },
    [t]
  );

  // const therapyPlanOptions = useMemo(() => {
  //   return planData?.map((plan) => ({
  //     value: plan.id,
  //     label: getTranslatableField(plan.title),
  //   }));
  // }, [planData]);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  // const handleGenerate = useCallback(
  //   async (therapyPlanId: string) => {
  //     if (!userProviderData?.id || !therapyPlanId) {
  //       return;
  //     }

  //     setTherapyPlanId(therapyPlanId);
  //     setGenerateDialogOpen(true);
  //   },
  //   [userProviderData?.id]
  // );

  const handleStartCourse = useCallback(
    (therapyCourseId: string) => {
      if (!userProviderData?.id || !email || !data) {
        return;
      }
      const updateData: Partial<TherapyCourse> = {
        ongoingPhase: 0,
        completed: false,
        completedDate: null,
      };

      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        email,
        'TherapyCourse',
        therapyCourseId
      );
      updateDoc(therapyCourseRef, updateData)
        .then(() => {
          console.log('Document successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setError(error);
        });
    },
    [data, email, firestore, userProviderData?.id]
  );

  // const { cleanPatientCourses } = useCleanPatientCourses();

  const confirmDelete = useCallback(() => {
    setDialogOpen(false);

    if (!deleteId || !userProviderData?.id || !email) {
      return;
    }
    const ref = doc(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'TherapyCourse',
      deleteId
    );
    deleteDoc(ref)
      .then(() => {
        console.log('Document successfully deleted!');
        // cleanPatientCourses();
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        setError(error);
      });
  }, [deleteId, email, firestore, userProviderData?.id]);

  const columns: GridColDef<TherapyCourse>[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: t('Title'),
        flex: 1,
        valueGetter: (_, row) => getTranslatableField(row.title),
      },
      {
        field: 'type',
        headerName: t('Type'),
        flex: 1,
        valueGetter: (_, row) => t(row.type || 'group'),
      },
      {
        field: 'created',
        headerName: t('Created'),
        flex: 1,
        valueGetter: (_, row) => (row.created ? row.created.toDate() : null),
        type: 'date',
      },
      {
        field: 'completedDate',
        headerName: t('Completed Date'),
        flex: 1,
        valueGetter: (_, row) =>
          row.completedDate ? row.completedDate.toDate() : null,
        type: 'date',
      },
      {
        field: 'status',
        headerName: t('Status'),
        flex: 1,
        valueGetter: (_, row) =>
          t(
            row.ongoingPhase === null
              ? row.completed
                ? 'Completed'
                : 'New'
              : 'Ongoing'
          ),
      },
      {
        field: 'description',
        headerName: t('Description'),
        flex: 1,
        valueGetter: (_, row) =>
          htmlToText(getTranslatableField(row.description)),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        getActions: ({ id, row }) => {
          const route = `/therapycourses/${row.id}`;

          const overviewRoute = `/therapycourseoverview/${row.id}`;
          return [
            row.ongoingPhase === null && !row.completed ? (
              <Tooltip title={t('Start Course Immediately')} key="start">
                <GridActionsCellItem
                  disabled={row.ongoingPhase !== null}
                  icon={<PlayIcon />}
                  label="Start"
                  onClick={() => handleStartCourse(row.id || '')}
                  color="inherit"
                />
              </Tooltip>
            ) : (
              <Tooltip title={t('View Course Session Overview')} key="view">
                <GridActionsCellItem
                  icon={<LinkIcon />}
                  label="View"
                  onClick={() => navigate(overviewRoute)}
                  color="inherit"
                />
              </Tooltip>
            ),
            <Tooltip title={t('Edit')} key="edit">
              <GridActionsCellItem
                disabled={!row.id}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                color="inherit"
                onClick={() => navigate(route)}
              />
            </Tooltip>,
            <Tooltip title={t('Delete')} key="delete">
              <GridActionsCellItem
                disabled={!row.id}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() =>
                  handleDelete(row.id || '', getTranslatableField(row.title))
                }
                color="inherit"
              />
            </Tooltip>,
          ];
        },
      },
    ],
    [t, handleStartCourse, navigate, handleDelete]
  );

  return (
    <>
      {/* <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          <Trans>Create New Therapy Course</Trans>
        </Typography>
        <Autocomplete
          options={therapyPlanOptions || []}
          getOptionLabel={(option) => option.label}
          blurOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Create From Therapy Plan...')}
              placeholder={t('Select Therapy Plan...')}
            />
          )}
          value={therapyPlanOptions?.find(
            (option) => option.value === selectedPlan
          )}
          onChange={(_, newValue) => setSelectedPlan(newValue?.value || null)}
        />
        <ButtonGroup sx={{ mt: 1 }} fullWidth size="large">
          <Button
            startIcon={<AddBoxIcon />}
            color="primary"
            variant="contained"
            disabled={!selectedPlan}
            onClick={() => handleGenerate(selectedPlan || '')}
          >
            {t('Create Therapy Course From Plan')}
          </Button>
          <Button
            component={Link}
            to={'/therapycourses/new'}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            <Trans>New Empty Therapy Course</Trans>
          </Button>
        </ButtonGroup>
      </Paper> */}
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        {error && <Alert severity="error">{error.message}</Alert>}
        {userProviderStatus === 'error' && (
          <Alert severity="error">{userProviderError?.message}</Alert>
        )}
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          <Trans>Therapy Courses</Trans>
        </Typography>
        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
          <FormControlLabel
            label={t('Show Current Courses Only')}
            control={
              <Checkbox
                checked={showActiveOnly}
                onChange={(e) => setShowActiveOnly(e.target.checked)}
              />
            }
          />
        </Stack>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            },
          }}
          autoHeight
          rows={data || []}
          columns={columns}
          loading={status === 'loading' || userProviderStatus === 'loading'}
          rowSelection={false}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Paper>
      {/* <GenerateCourseFromPlanDialog
        therapyPlanId={therapyPlanId}
        generateDialogOpen={generateDialogOpen}
        setGenerateDialogOpen={setGenerateDialogOpen}
      /> */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle || <Trans>Delete Therapy Course</Trans>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent || (
              <Trans>
                Are you sure you want to delete this therapy course?
              </Trans>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
